import { getEnv, resolve } from 'core/utils/env';

import { dark, darkMobile, light, lightMobile } from 'site/themes';

const HOST = 'moslenta.ru';


/**
 * Этот конфиг вынесен в отдельный файл чтоб шарить конфигурацию сайта
 * с галереей компонентов.
 * Сюда ни в коем случае не должно добавляться что-то кроме конфигурации
 * компонента App.
 */
export default function resolveConfig(requestHeaders) {
  const hostName = requestHeaders
    ? resolve({
      'dev': HOST,
      '*': requestHeaders.host,
    }) : HOST;

  const siteUrl = 'https://' + hostName;

  return {
    siteName: 'moslenta',
    host: hostName,
    theme: 'light',
    themes: {
      light: {
        base: light,
        mobile: lightMobile,
      },
      dark: {
        base: dark,
        mobile: darkMobile,
      },
    },

    coreApisConfig: {
      bebopApi: resolve({
        'dev': '//s1.moslenta.ru/api/v2',
      }),
    },

    webdavHost: resolve({
      '*': getEnv('WEBDAV_HOST'),
      'dev': '//wcdn.stage.unity.rambler.ru/moslenta',
    }),

    thumborHost: resolve({
      '*': getEnv('THUMBOR_HOST'),
      'dev': '//s1.thumbor.unity.rambler.tech/unsafe',
    }),

    erratumUrl: `${siteUrl}/erratum`,

    schemaOrg: {
      organizationName: 'Мослента',
      /**
       * Логотип должен быть прямоугольным и лежать в пределах 60x600px:
       * https://developers.google.com/search/docs/data-types/article#logo-guidelines
       */
      organizationLogoUrl: siteUrl + '/icons/publisher-logo.png',
      organizationLogoWidth: 459,
      organizationLogoHeight: 60,
    },

    defaultSiteMeta: {
      titleTemplate: '%s - Мослента',
      defaultTitle: 'Последние новости Москвы, события и все развлечения в городе',
      meta: [
        { name: 'description',
          content: 'Рассказываем о Москве не только для москвичей. Истории людей – от звезд до простых горожан, интересные события, столичные новости, урбанистика и многое другое.',
        },
      ],
    },

    ramblerCommentsAppId: resolve({
      '*': 125,
      'dev': 195,
    }),

    isUnityProject: true,

    ads: {
      siteId: '433125164',
      antiadblock: true,
    },

    features: {
      enableRetina: true,
      isNewVersionPremiumTopic: true,
      enableFenwick: true,
    },

    counterIds: {
      mailru: 3090302,
      googleAnalytics: 'UA-18447330-43',
      ga4: 'G-YELM7RHYT3',
      tns: { account: 'rambler_ru', tmsec: 'moslenta_total' },
      mediascope: 'rambler_cid1100108-posid2155107/',
      top100: 3095378,
      yandexMetrika: 29166575,
      liveInternet: { ids: ['', 'Lenta', 'gazeta_all'] },
      rcm: resolve({
        '*': 'RCM-F275',
        'production': 'RCM-22DC',
      }),
      loyalty: true,
    },

    pushWoosh: {
      applicationCode: resolve({
        '*': '4205D-EDD3A',
        'staging': 'BD2D1-FE258',
      }),
      safariWebsitePushID: 'web.moslenta.ru',
      defaultNotificationTitle: '',
      defaultNotificationImage: siteUrl + '/icons/notification-logo.png',
    },

    specialLinks: [
      '/about', // чтобы работала хеш навигация
      '/exports/rss.xml',
      '/urbanistika/moskovskie-proekty.htm',
    ],

    ramblerId: {
      rname: 'lentaru',
      theme: 'moslenta',
    },

    socials: [
      { name: 'Vkontakte', link: 'https://vk.com/moslentaru' },
      { name: 'Odnoklassniki', link: 'https://ok.ru/group/52836567548038' },
      { name: 'Telegram', link: 'https://t.me/moslentaru' },
      { name: 'Yandex_Dzen', link: 'https://dzen.ru/moslenta?favid=1670' },
    ],
  };
}
